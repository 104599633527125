

#phyloblastMenu{
  margin: 10px 0px 10px 0px;
}

#phyloblastMenu label {
 margin-left: 10px;
 margin-right: 5px;
}

/* download menu */

.card{
  width: 31%;
  /*float: right;*/
}

.card-header{
  background-color: white;
  border: white;
}

.card-body{
  /*border: solid 1px #fcb42d;*/
}



/* tree window */
/* rank selection*/
#collapse_menu.dropdown-toggle.btn.btn-primary{
  background-color: #fcb42d;
  border:transparent;
}

/* barplot selection*/
#tree_menu.dropdown-toggle.btn.btn-primary{
  background-color: #fcb42d;
  border:transparent;
}

/* selected values for tax rank and bar chart*/
#selectedValues.form-inline{
  background-color: #ffd17a;
}

.btn-toolbar{
  background-color: #ffd17a;
}

.selectedValues {
  margin: 0 10px 0 10px;
}

#selectedValues {
  padding: 0 5px 0 5px;
}



/* buttons in dropdown menu downloads*/
.btn.btn.primary, #public_ready_taxa, #csvDataExport, #phyloblastMenu #txtDataExport {
  background: transparent;
  color: white;
}

.mr-auto button:hover, #public_ready_taxa:hover, #csvDataExport:hover, #phyloblastMenu #txtDataExport:hover{
}


#taxaPhylo, #uniquePhylo{
 border: none;
 background: transparent;
 display: block;
 color: white;
 width: 100%;
 text-align: center;
}

#phyloblastMenu button {
  margin: 5px 5px 5px 10px;
}

a.btn.btn-primary {
  margin: 5px 5px 5px 10px;
}


#taxaPhylo:hover, #uniquePhylo:hover{
 color: white;
 width: 100%;
 text-decoration: none;
}

#phyloblastMenu h2{
 padding: 2px 0 2px 20px;
 margin: 5px 0 0 0 ;
 font-size: 17px;
 color: white;
 background: #377ba8;
 border: solid 1.5px white;
}


.nav-tabs{
  padding-top: 10px;
  background-color:#f2f0ed;
  /*border-bottom: 1px solid #495057;*/
}


.nav-tabs .nav-link {
  border: 1px solid transparent;
  color: lightgrey;
}

.tab-content{
    background-color:#f2f0ed;
    color:#fff;
    padding:5px
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    /*border-color: #495057 #495057 #fff;*/
}

.nav-link:hover{
  color: #495057;
  background-color: #f7f7f5;
}

/***
#phyloLink {
  background-color:  #9dbde3;
  border: 1.5px solid #5e9fec;
  background-image: linear-gradient(to top, rgba(94,159,236,1) 10%, rgba(193,208,237,1) 90%);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  color: black;
  text-decoration: none;
}

#phyloLink:hover {
  background-color:  #6592d4;
  border: 1.5px solid #5175ab;
}
***/

