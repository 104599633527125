
#phylogenyMenu {
  margin: 0px 0px 10px 0px;
}

#phylogenyMenu label, #phylogenyMenu select,  #additional_file {
  margin: 0 0 0 5px;
}

#phylogenyMenu button {
  margin: 5px 0 0 10px;
}

#phylogenyMenu li{
 display: inline-block;
 width: 50%;
 list-style-type: none;
 text-align: center;
 padding: 5px 5px 5px 0px;
 background: #8e93ca;
 border: solid 1.5px white;
}


#phylogenyMenu h2{
 padding: 2px 0 2px 20px;
 margin: 5px 0 0 0 ;
 font-size: 17px;
 color: white;
 background: #5e66b4;
 border: solid 1.5px white;
}

#phylogenyMenu button {
  margin: 5px 5px 5px 10px;
}

#additionalInfo .labels {
  overflow: visible;
}

#public_ready_phylo:disabled, #public_ready_phylo:disabled:hover {
  background: #dbdcf1;
}

#phylogenybar {
  background-color: #89cc9a;
}

#cladogram.btn.btn-primary {
  background-color: #4d9660;
  border: transparent;
}

#phylogram.btn.btn-primary {
  background-color: #4d9660;
  border: transparent;
}