
#menu legend {
  max-width: 200px;
  text-align: center;
  padding: 5px 7px 5px 7px;
  border: 2px solid #6495ED;
  border-radius: 4px;
  background: white; 
  font-size: 1rem;
  font-weight: bold;
}

#menu label {
  margin-right: 5px;
}

#menu .form-group{
  margin: 5px 0px 10px 10px;
}

#menu .helpIcon {
  margin-left: 5px;
}

#menu fieldset{
  border-radius: 5px;
  border: 1px solid grey;
}

#menu fieldset > * {
  margin: 5px 0px 5px 15px;
}

#menu {
  margin: 30px 10px;
}

 #menu select{
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-familiy: sans-serif;
}

#menu textarea{
  width: 95%;
}

#menu .form-file{
  margin-top: 10px;
}

.popover-header {
  color: white;
  background: #79acce;
}

.popover-body {
  background: #dae7f1;
}

#blasttypes{
  margin: 0 0 0 10px;
}


#Searchbar {
  width: 80%;
}

#SearchbarContent {
  display: flex;
  flex-direction: column;
}

#SearchResult {
  height: auto;
  max-height: 200px;
  overflow: scroll;
  width: 70%;
  opacity: 0;
  margin: 0 0 5px 0;
}




.react-search-field {
  width:70%;
}

