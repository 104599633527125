html {
  font-family: sans-serif;
  background: white;
}

body {
  margin: 0 auto;
  background: white;
}

.App-body {
  padding: 20px 10% 40px 10%;
}

.App-header {
  width: 100%;
}

.App-header h1, .App-header h2, .App-header h3, .App-header h5, .App-header h6, #title{
  font-family: serif;
  color: #002060;
  margin: 10rem;
  text-align: left;
  font-weight: bold;
  font-size: 1.75rem;
}

#subtitle {
  font-family: arial;
  color: #002060;
  margin: 0px 15px;
  text-align: left;
  font-weight: bold;
  font-size: 1em;
  font-style: italic;
}

 #menuLink, #helpLink{
  border: none;
  color: #377ba8;
  text-decoration: none;
  background: #f2f0ed;
  padding: 20px 10px;
  text-align: center;
  font-size: 1em;
}

/* #link1:hover, #link2:hover{
  background: #377ba8;
}

#menuLink:hover, #helpLink:hover{
  border:none;
  color: white;
  text-decoration: none;
  background: #377ba8;
} */



.App-header hr {
  border: none;
  border-top: 1px solid white;
}

/* Website header styling */
.App-header nav {
  background: #f2f0ed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px 0.5rem;
}

.App-header nav h1, #title {
  flex: auto;
  margin: 0px 15px;
}

.App-header nav, .App-header h1, #title{
  text-decoration: none;
  padding: 0.5rem 0.5rem;
}

.App-header nav, .App-header ul  {
  display: flex;
  list-style: none;
  padding: 5px 20px 5px 30px;
}



#headerName {
   width: 30%
}

#subMenu {
  justify-content: flex-end;
}

#subMenu li {
  align-self: stretch;
}


.App-header nav ul li a, .App-header nav ul li span, .App-header header .action {
  display: block;
  padding: 0.5rem;
}

/* Error message*/
#alert {
  padding: 10px;
  background-color: #c2291a;
  color: white;
}

#alert ul{
 display: block;
}

#alert li{
 margin: 0 0 5px 0;
}

.App-header .closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.App-header .closebtn:hover {
  color: black;
}

/* Visualisation div style */
#treeVis{
  width: 100%;
  height: 80vh;
  display: flex;
  overflow: auto;
}


#tree{
  display: block;
  overflow: visible;
}

#additionalInfo{
  display: inline-block;
  overflow: visible;
  text-overflow: ellipsis
}

#tree_vis{
  margin: 0 20px 0 5px;
  overflow: visible;
}

/* Help page style */
#helpInfo{
  margin: 20px 40px 20px 40px;
}

#helpInfo img {
  max-width: 100%;
  max-height: 100%;
}


.helpHeader {
  border-bottom: 2px solid lightgray;
}

h4.helpHeader {
  padding: 10px 0 0 0;
}

#howtoHelpInfo .subList {
  margin: 10px 15px;
  /* HOW-TO change list style -->   list-style-type: lower-alpha; == a) b) ... */
}